import dayjs from 'dayjs';
// Plugins
import isoWeekPlugin from 'dayjs/plugin/isoWeek';
import quarterOfYearPlugin from 'dayjs/plugin/quarterOfYear';
import isSameOrAfterPlugin from 'dayjs/plugin/isSameOrAfter';
import isSameOrBeforePlugin from 'dayjs/plugin/isSameOrBefore';
import isBetweenPlugin from 'dayjs/plugin/isBetween';
import dayOfYearPlugin from 'dayjs/plugin/dayOfYear';
import advancedFormatPlugin from 'dayjs/plugin/advancedFormat';
import customParseFormatPlugin from 'dayjs/plugin/customParseFormat';
// Timezone Plugins
import utcPlugin from 'dayjs/plugin/utc';
import timezonePlugin from 'dayjs/plugin/timezone';

import { defaultDateFormat, ISODateFormat } from './format-patterns';

dayjs.extend(isoWeekPlugin);
dayjs.extend(quarterOfYearPlugin);
dayjs.extend(isSameOrAfterPlugin);
dayjs.extend(isSameOrBeforePlugin);
dayjs.extend(isBetweenPlugin);
dayjs.extend(dayOfYearPlugin);
dayjs.extend(advancedFormatPlugin);
dayjs.extend(customParseFormatPlugin);
// Timezone
dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);

export {
  // formats
  ISODateFormat,
  defaultDateFormat,
};

const parseDateToDateFormat = (inputDate, dateFormat) => {
  const date = new Date(inputDate);
  const dateWithZeroOffset = new Date(date.getTime() + (date.getTimezoneOffset() * 60000));

  return dayjs(dateWithZeroOffset).format(dateFormat);
};

/**
 * @name isMatch
 * @param {Date|string|number} date
 * @param {string} matchFormat
 * @returns {boolean} true|false
 * @example
 * isMatch('2020-11-20', 'YYYY-MM-DD') // => true
 * isMatch('02/20/2020', 'MM/DD/YYYY') // => true
 * isMatch('2020-02-30', 'YYYY-MM-DD') // => false
 * isMatch('02/20/2020', 'YYYY-MM-DD') // => false
*/
export function isMatch(date, matchFormat) {
  return dayjs(date, matchFormat, true).isValid();
}

/**
 * @name format
 * @param {Date|string|number} date
 * @param {string|undefined} dateFormat
 * @returns {string}
 *
 * @example
 * format(new Date()) // => '08/23/2021'
 * format('2020-11-20') // => '11/20/2020'
 * format('02/20/2020', ISODateFormat) // => '2020-02-20'
 * format('07/20/2020', 'DD, MMM YYYY') // => '20, Jul 2020'
 */
export function format(date, dateFormat = defaultDateFormat) {
  if (typeof date === 'string' && (isMatch(date, ISODateFormat) || isMatch(date, defaultDateFormat))) {
    return dayjs(date).format(dateFormat);
  }

  return parseDateToDateFormat(date, dateFormat);
}

/**
 * @name today
 * @returns {string} 'YYYY-MM-DD'
 */
export function today() {
  return dayjs().format(ISODateFormat);
}

/**
 * @name yesterday
 * @returns {string} 'YYYY-MM-DD'
 */
export function yesterday() {
  return dayjs().subtract(1, 'day').format(ISODateFormat);
}

// Adding / Subtracting
/**
 * @name addDays
 * @param {Date|string|number} date
 * @param {number} amount
 * @returns {string} 'YYYY-MM-DD'
 *
 * @example
 * addDays('2020-11-20', 2) // => '2020-11-22'
 * addDays('11/20/2020', 10) // => '2020-11-30'
 */
export function addDays(date, amount = 0) {
  return dayjs(format(date)).add(amount, 'day').format(ISODateFormat);
}

/**
 * @name addWeeks
 * @param {Date|string|number} date
 * @param {number} amount
 * @returns {string} 'YYYY-MM-DD'
 *
 * @example
 * addWeeks('2016-02-23', 4) // => '2016-03-22'
 * addWeeks('02/23/2016', 4) // => '2016-03-22'
 */
export function addWeeks(date, amount = 0) {
  return dayjs(format(date)).add(amount, 'week').format(ISODateFormat);
}

/**
 * @name addMonths
 * @param {Date|string|number} date
 * @param {number} amount
 * @returns {string} 'YYYY-MM-DD'
 *
 * @example
 * addMonths('2016-02-23', 12) // => '2017-02-23'
 * addMonths('02/23/2016', 12) // => '2017-02-23'
 */
export function addMonths(date, amount = 0) {
  return dayjs(format(date)).add(amount, 'month').format(ISODateFormat);
}

/**
 * @name addQuarters
 * @param {Date|string|number} date
 * @param {number} amount
 * @returns {string} 'YYYY-MM-DD'
 *
 * @example
 * addQuarters('2016-02-23', 1) // => '2016-05-23'
 * addQuarters('02/23/2016', 1) // => '2016-05-23'
 */
export function addQuarters(date, amount = 0) {
  return dayjs(format(date)).add(amount, 'quarter').format(ISODateFormat);
}

/**
 * @name addYears
 * @param {Date|string|number} date
 * @param {number} amount
 * @returns {string} 'YYYY-MM-DD'
 *
 * @example
 * addYears('2016-02-23', 1) // => '2017-02-23'
 * addYears('02/23/2016', 1) // => '2017-02-23'
 */
export function addYears(date, amount = 0) {
  return dayjs(format(date)).add(amount, 'year').format(ISODateFormat);
}

/**
 * @name subDays
 * @param {Date|string|number} date
 * @param {number} amount
 * @returns {string} 'YYYY-MM-DD'
 *
 * @example
 * subDays('2020-11-20', 2) // => '2020-11-18'
 * subDays('11/20/2020', 10) // => '2020-11-10'
 */
export function subDays(date, amount = 0) {
  return dayjs(format(date)).subtract(amount, 'day').format(ISODateFormat);
}

/**
 * @name subWeeks
 * @param {Date|string|number} date
 * @param {number} amount
 * @returns {string} 'YYYY-MM-DD'
 *
 * @example
 * subWeeks('2016-02-23', 2) // => '2016-01-26'
 * subWeeks('02/23/2016', 2) // => '2016-01-26'
 */
export function subWeeks(date, amount = 0) {
  return dayjs(format(date)).subtract(amount, 'week').format(ISODateFormat);
}

/**
 * @name subMonths
 * @param {Date|string|number} date
 * @param {number} amount
 * @returns {string} 'YYYY-MM-DD'
 *
 * @example
 * subMonths('2016-02-23', 12) // => '2015-02-23'
 * subMonths('02/23/2016', 12) // => '2015-02-23'
 */
export function subMonths(date, amount = 0) {
  return dayjs(format(date)).subtract(amount, 'month').format(ISODateFormat);
}

/**
 * @name subQuarters
 * @param {Date|string|number} date
 * @param {number} amount
 * @returns {string} 'YYYY-MM-DD'
 *
 * @example
 * subQuarters('2016-02-23', 1) // => '2015-11-23'
 * subQuarters('02/23/2016', 1) // => '2015-11-23'
 */
export function subQuarters(date, amount = 0) {
  return dayjs(format(date)).subtract(amount, 'quarter').format(ISODateFormat);
}

/**
 * @name subYears
 * @param {Date|string|number} date
 * @param {number} amount
 * @returns {string} 'YYYY-MM-DD'
 *
 * @example
 * subYears('2016-02-23', 1) // => '2015-02-23'
 * subYears('02/23/2016', 1) // => '2015-02-23'
 */
export function subYears(date, amount = 0) {
  return dayjs(format(date)).subtract(amount, 'year').format(ISODateFormat);
}

// StarOf/ EndOf
/**
 * @name startOfWeek
 * @param {Date|string|number} date
 * @returns {string} 'YYYY-MM-DD'
 *
 * @example
 * startOfWeek('2020-11-20') // => '2020-11-15'
 * startOfWeek('11/20/2020') // => '2020-11-15'
 */
export function startOfWeek(date) {
  return dayjs(format(date)).startOf('week').format(ISODateFormat);
}

/**
 * @name startOfISOWeek
 * @param {Date|string|number} date
 * @returns {string} 'YYYY-MM-DD'
 *
 * @example
 * startOfISOWeek('2020-11-20') // => '2020-11-16'
 * startOfISOWeek('11/20/2020') // => '2020-11-16'
 */
export function startOfISOWeek(date) {
  return dayjs(format(date)).startOf('isoWeek').format(ISODateFormat);
}

/**
 * @name startOfMonth
 * @param {Date|string|number} date
 * @returns {string} 'YYYY-MM-DD'
 *
 * @example
 * startOfMonth('2020-11-20') // => '2020-11-01'
 * startOfMonth('11/20/2020') // => '2020-11-01'
 */
export function startOfMonth(date) {
  return dayjs(format(date)).startOf('month').format(ISODateFormat);
}

/**
 * @name startOfQuarter
 * @param {Date|string|number} date
 * @returns {string} 'YYYY-MM-DD'
 *
 * @example
 * startOfQuarter('2020-11-20') // => '2020-10-01'
 * startOfQuarter('11/20/2020') // => '2020-10-01'
 */
export function startOfQuarter(date) {
  return dayjs(format(date)).startOf('quarter').format(ISODateFormat);
}

/**
 * @name startOfYear
 * @param {Date|string|number} date
 * @returns {string} 'YYYY-MM-DD'
 *
 * @example
 * startOfYear('2020-11-20') // => '2020-01-01'
 * startOfYear('11/20/2020') // => '2020-01-01'
 */
export function startOfYear(date) {
  return dayjs(format(date)).startOf('year').format(ISODateFormat);
}

/**
 * @name endOfWeek
 * @param {Date|string|number} date
 * @returns {string} 'YYYY-MM-DD'
 *
 * @example
 * endOfWeek('2020-11-20') // => '2020-11-21'
 * endOfWeek('11/20/2020') // => '2020-11-21'
 */
export function endOfWeek(date) {
  return dayjs(format(date)).endOf('week').format(ISODateFormat);
}

/**
 * @name endOfISOWeek
 * @param {Date|string|number} date
 * @returns {string} 'YYYY-MM-DD'
 *
 * @example
 * endOfISOWeek('2020-11-20') // => '2020-11-22'
 * endOfISOWeek('11/20/2020') // => '2020-11-22'
 */
export function endOfISOWeek(date) {
  return dayjs(format(date)).endOf('isoWeek').format(ISODateFormat);
}

/**
 * @name endOfMonth
 * @param {Date|string|number} date
 * @returns {string} 'YYYY-MM-DD'
 *
 * @example
 * endOfMonth('2020-11-20') // => '2020-11-30'
 * endOfMonth('12/20/2020') // => '2020-12-31'
 */
export function endOfMonth(date) {
  return dayjs(format(date)).endOf('month').format(ISODateFormat);
}

/**
 * @name endOfQuarter
 * @param {Date|string|number} date
 * @returns {string} 'YYYY-MM-DD'
 *
 * @example
 * endOfQuarter('2020-04-20') // => '2020-06-30'
 * endOfQuarter('20/10/2020') // => '2020-12-31'
 */
export function endOfQuarter(date) {
  return dayjs(format(date)).endOf('quarter').format(ISODateFormat);
}

/**
 * @name endOfYear
 * @param {Date|string|number} date
 * @returns {string} 'YYYY-MM-DD'
 *
 * @example
 * endOfYear('2020-11-20') // => '2020-12-31'
 * endOfYear('12/20/2020') // => '2020-12-31'
 */
export function endOfYear(date) {
  return dayjs(format(date)).endOf('year').format(ISODateFormat);
}

// Difference
/**
 * @name differenceInDays
 * @param {Date|string|number} dateLeft
 * @param {Date|string|number} dateRight
 * @returns {numbers} 10
 *
 * @example
 * differenceInDays('2020-02-10', '2021-02-09') // => -365
 * differenceInDays('02/09/2021', '02/10/2020') // => 365
 */
export function differenceInDays(dateLeft, dateRight) {
  return dayjs(format(dateLeft)).diff(format(dateRight), 'day');
}

// Comparison
/**
 * @name isBefore
 * @param {Date|string|number} dateLeft
 * @param {Date|string|number} dateRight
 * @returns {boolean} true|false
 *
 * @example
 * isBefore('2020-02-10', '2021-02-09') // => true
 * isBefore('02/09/2021', '02/10/2020') // => false
 */
export function isBefore(dateLeft, dateRight) {
  return dayjs(format(dateLeft)).isBefore(format(dateRight));
}

/**
 * @name isAfter
 * @param {Date|string|number} dateLeft
 * @param {Date|string|number} dateRight
 * @returns {boolean} true|false
 *
 * @example
 * isAfter('2020-02-10', '2021-02-09') // => false
 * isAfter('02/09/2021', '02/10/2020') // => true
 */
export function isAfter(dateLeft, dateRight) {
  return dayjs(format(dateLeft)).isAfter(format(dateRight));
}

/**
 * @name isSame
 * @param {Date|string|number} dateLeft
 * @param {Date|string|number} dateRight
 * @returns {boolean} true|false
 *
 * @example
 * isSame('2020-02-10', '2021-02-09') // => false
 * isSame('02/09/2021', '02/09/2021') // => true
 */
export function isSame(dateLeft, dateRight) {
  return dayjs(format(dateLeft)).isSame(format(dateRight));
}

/**
 * @name isSameOrBefore
 * @param {Date|string|number} dateLeft
 * @param {Date|string|number} dateRight
 * @returns {boolean} true|false
 *
 * @example
 * isSameOrBefore('2020-02-10', '2021-02-09') // => true
 * isSameOrBefore('2021-02-09', '2020-02-10') // => false
 * isSameOrBefore('02/09/2021', '02/09/2021') // => true
 */
export function isSameOrBefore(dateLeft, dateRight) {
  return dayjs(format(dateLeft)).isSameOrBefore(format(dateRight));
}

/**
 * @name isSameOrAfter
 * @param {Date|string|number} dateLeft
 * @param {Date|string|number} dateRight
 * @returns {boolean} true|false
 *
 * @example
 * isSameOrAfter('2020-02-10', '2021-02-09') // => false
 * isSameOrAfter('2021-02-09', '2020-02-10') // => true
 * isSameOrAfter('02/09/2021', '02/09/2021') // => true
 */
export function isSameOrAfter(dateLeft, dateRight) {
  return dayjs(format(dateLeft)).isSameOrAfter(format(dateRight));
}

/**
 * @name isSameWeek
 * @param {Date|string|number} dateLeft
 * @param {Date|string|number} dateRight
 * @returns {boolean} true|false
 *
 * @example
 * isSameWeek('2020-11-20', '2020-11-16') // => true
 * isSameWeek('11/20/2020', '10/16/2020') // => false
 */
export function isSameWeek(dateLeft, dateRight) {
  return dayjs(format(dateLeft)).isSame(format(dateRight), 'week');
}

/**
 * @name isSameISOWeek
 * @param {Date|string|number} dateLeft
 * @param {Date|string|number} dateRight
 * @returns {boolean} true|false
 *
 * @example
 * isSameISOWeek('2021-08-16', '2021-08-23') // => false
 * isSameISOWeek('2021-08-16', '2021-08-22') // => true
 */
export function isSameISOWeek(dateLeft, dateRight) {
  return dayjs(format(dateLeft)).isSame(format(dateRight), 'isoWeek');
}

/**
 * @name isSameMonth
 * @param {Date|string|number} dateLeft
 * @param {Date|string|number} dateRight
 * @returns {boolean} true|false
 *
 * @example
 * isSameMonth('2020-11-20', '2020-11-16') // => true
 * isSameMonth('09/20/2020', '10/16/2020') // => false
 */
export function isSameMonth(dateLeft, dateRight) {
  return dayjs(format(dateLeft)).isSame(format(dateRight), 'month');
}

/**
 * @name isSameQuarter
 * @param {Date|string|number} dateLeft
 * @param {Date|string|number} dateRight
 * @returns {boolean} true|false
 *
 * @example
 * isSameQuarter('2020-10-20', '2020-11-16') // => true
 * isSameQuarter('09/20/2020', '11/16/2020') // => false
 */
export function isSameQuarter(dateLeft, dateRight) {
  return dayjs(format(dateLeft)).isSame(format(dateRight), 'quarter');
}
/**
 * @name isSameYear
 * @param {Date|string|number} dateLeft
 * @param {Date|string|number} dateRight
 * @returns {boolean} true|false
 *
 * @example
 * isSameYear('2020-11-20', '2020-11-16') // => true
 * isSameYear('09/20/2020', '10/16/2021') // => false
 */
export function isSameYear(dateLeft, dateRight) {
  return dayjs(format(dateLeft)).isSame(format(dateRight), 'year');
}

/**
 * @name isValid
 * @param {Date|string|number} date
 * @returns {boolean} true|false
 * @example
 * isValid('2020-11-20') // => true
 * isValid('02/20/2020') // => true
 * isValid('2020-02-30') // => false
*/
export function isValid(date) {
  return dayjs(date).isValid();
}

/**
 * @name isBeforeHistoricalStartDate
 * @param {Date|string|number} date
 * @returns {boolean} true|false
 *
 * @example
 * isBeforeHistoricalStartDate('2014-12-31') // => true
 * isBeforeHistoricalStartDate('02/09/2021') // => false
 */
export function isBeforeHistoricalStartDate(date) {
  return isBefore(date, HISTORICAL_START_DATE);
}

/**
 * @name getDayOfYear
 * @param {Date|string|number} date
 * @returns {number} 1
 *
 * @example
 * getDayOfYear('2020-11-20') // => 325
 * getDayOfYear('02/20/2020') // => 51
 * getDayOfYear('2020-01-01') // => 1
*/
export function getDayOfYear(date) {
  return dayjs(format(date)).dayOfYear();
}

/**
 * @name getDateByTimezone
 * @param {string} timeZone
 * @param {object} additionalOptions
 * @param {Date} date
 * @returns {string}
 * @example
 * getDateByTimezone('America/New_York') // => '12/26/2024'
 * getDateByTimezone('America/New_York', { hour: 'numeric', hour12: false }) // => '09'
*/
export const getDateByTimezone = (timeZone, additionalOptions = {}, date = new Date()) => (
  new Intl.DateTimeFormat('en-US', {
    timeZone,
    ...additionalOptions,
  }).format(date));

/**
 * @name cutoffDate
 * @param {Date|string|number} date
 * @param {string} maxDate
 * @returns {Date|string|number}}
 */
export const cutoffDate = (date, maxDate) => {
  if (!maxDate) return date;

  return isAfter(date, maxDate) ? maxDate : date;
};

/**
 * @name toJSDate
 * @param {Date|number|string} date
 * @returns {Date}
 *
 * @example
 * toJSDate('2020-11-23') // => Mon Nov 23 2020 12:36:00 GMT+0200 (Eastern European Standard Time)
 * toJSDate('02/12/2021') // => Fri Feb 12 2021 12:38:00 GMT+0200 (Eastern European Standard Time)
 */
export function toJSDate(date) {
  return dayjs(format(date)).toDate();
}
